



















































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import Common from '../../common/Common'
import VueTagsInput from '@johmun/vue-tags-input'
import VueUploadComponent from 'vue-upload-component'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import markdownIt from "markdown-it"

@Component({
  components: {
    VueTagsInput,
    VueUploadComponent,
    ClipLoader
  },
})
export default class ClassCreate extends Vue {
  isUploading = true
  md = new markdownIt({html: false, breaks: true})
  userInfo: any = {}
  errorMessage = ''
  fileErrorMessage = ''
  title = ''
  detail = ''
  detailSelectionStart = 0
  tag = ''
  tags: any[] = []
  categoryValue = ''
  categories: any[] = []
  autoCompletes: {text: string}[] = []
  filteredItems: {text: string}[] = []
  date = ''
  reward = ''
  peopleLimit = ''
  files: any[] = []
  imageFile: any
  accept = "image/*,application/pdf,text/plain,text/csv,application/json"

  mounted() {
    if (!Common.useWebRTC()) {
      this.$router.push('/unsupport')
      return
    }

    let promises = []
    if (this.$route.params.id) {
      firebase.firestore().collection('onayamis').doc(this.$route.params.id).get().then((onayami) => {
        let data = onayami.data()!
        this.title = data.title
        this.detail = data.detail
        data.tags.forEach((tag: string) => {
          this.tags.push({text: tag})
        });
        this.date = data.date
        this.reward = data.reward
        this.peopleLimit = data.peopleLimit
        this.categoryValue = data.category
        let promises = []

        if (data.files && data.files.length != 0) {
          promises.push(new Promise((resolve) => {
            data.files.forEach((file: string) => {
              firebase.storage().ref(file).getDownloadURL().then((url) => {
                Common.getBlob(url).then((blob: any) => {
                  let fileName = file.split('/')[3]
                  blob.id = fileName
                  blob.name = fileName
                  blob.fileName = file
                  blob.updated = true
                  if (blob.type.substr(0, 6) === 'image/') {
                    blob.thumb = true
                  }
                  this.files.push(blob)
                })
              }).finally(() => {
                resolve()
              })
            })
          }))

          Promise.all(promises).finally(() => {
            this.isUploading = false
          })
        } else {
          this.isUploading = false
        }
        Common.getUserInfo().then((userInfo: any) => {
          this.userInfo = userInfo
        })
      })
    } else {
      promises.push(new Promise((resolve) => {
        Common.getUserInfo().then((userInfo: any) => {
          this.userInfo = userInfo
          this.date = userInfo.date
        }).finally(() => {
          resolve()
        })
      }))
    }

    promises.push(new Promise((resolve) => {
      Common.getCategory().then((categories: any) => {
        this.categories = categories
      }).finally(() => {
        resolve()
      })
    }))

    promises.push(new Promise((resolve) => {
      firebase.firestore().collection('tags').get().then((data) => {
        data.docs.forEach((value, index) => {
          let tag = value.data().tag
          this.autoCompletes.push({text: tag})
        })
      }).finally(() => {
        resolve()
      })
    }))

    Promise.all(promises).finally(() => {
      if (!this.$route.params.id) {
        this.isUploading = false
      }
    })
  }

  detailBlur() {
    this.detailSelectionStart = (<any>document.getElementById('detail')!).selectionStart
  }

  compiledMarkdownText() {
    return this.md.render(this.detail)
  }

  inputFile(newFile: any, oldFile:any) {
    if (newFile.size >= 33554432) {
      this.fileRemove(newFile)
      this.fileErrorMessage = 'ファイルの容量は32MBまでです'
      return
    }
    this.fileErrorMessage = ''
  }

  inputFilter(newFile: any, oldFile:any, prevent:any) {
    let URL = window.URL || (<any>window).webkitURL
    if (URL && URL.createObjectURL) {
      newFile.blob = URL.createObjectURL(newFile.file)
    }

    if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
      newFile.thumb = newFile.blob
    }
  }

  fileRemove(file: any) {
    this.files.forEach((value, index) => {
      if (value == file) {
        this.files.splice(index, 1)
      }
    })
  }

  @Watch('tag')
  tagUpdate() {
    this.filteredItems = this.autoCompletes.filter((tag: {text: string}) => {
      return tag.text.indexOf(this.tag) != -1
    })
  }

  tagsChange(newTags: []) {
    this.tags = newTags
    if (Common.isMobile()) {
      (<any>document.activeElement!).blur()
      setTimeout(() => {
        this.tag = ''
      }, 100)
    }
  }

  async create() {
    if (this.tags.length > 5) {
      this.errorMessage = 'タグの設定は5個までです'
      return
    }
    this.errorMessage = ''
    this.isUploading = true

    let registTags: string[] = []
    this.tags.forEach((tag: {text: string}) => {
      registTags.push(tag.text)
      if (!this.autoCompletes.find((item: {text: string}) => item.text === tag.text)) {
        firebase.firestore().collection('tags').add({tag: tag.text})
      }
    })

    let fileFolder = Common.createUUID()
    var storageRef = firebase.storage().ref()
    let promise: any[] = []
    let registFiles: any[] = []
    for (let file of this.files) {
      let isImage = file.type.substr(0, 6) === 'image/'
      let fileName = file.fileName ? file.fileName : 'onayamis/' + fileFolder +  (isImage ?  `/${file.name}` : `/files/${file.name}`)
      registFiles.push(fileName)
      if (!file.updated) {
        if (isImage) {
          file.file = await Common.getCompressImageFileAsync(file.file)
          fileName = `onayamis/${fileFolder}/images/${file.name}`
        }
        promise.push(storageRef.child(fileName).put(file.file))
      }
    }
    let registId = ''
    if (this.$route.params.id) {
      promise.push(firebase.firestore().collection('onayamis').doc(this.$route.params.id)
      .update({
        title: this.title, 
        detail: this.detail, 
        files: registFiles, 
        tags: registTags, 
        date: this.date, 
        reward: Number(this.reward), 
        peopleLimit: Number(this.peopleLimit), category: this.categoryValue, 
        updateTime: firebase.firestore.FieldValue.serverTimestamp(), 
        type: 'class',
        prefecture: this.userInfo.prefecture

        }))
    } else {
      promise.push(new Promise((resolve) => {
        firebase.firestore().collection('onayamis')
        .add(
          {title: this.title, 
          detail: this.detail, 
          files: registFiles, 
          tags: registTags, 
          date: this.date, 
          reward: Number(10000), 
          peopleLimit: Number(20), 
          inClass: 0, 
          category: this.categoryValue, 
          userName: this.userInfo.name, 
          userFileExist: this.userInfo.fileExist, 
          uid: Common.getUserId(), 
          finish: false, createTime: 
          firebase.firestore.FieldValue.serverTimestamp(), 
          type: 'class',
          prefecture: this.userInfo.prefecture
          })
          .then((data) => {
            registId = data.id
          }).finally(() => {
            resolve()
          })
      }))
    }

    Promise.all(promise).then(() => {
      this.jump(registId)
    }).catch(() => {
      this.errorMessage = '登録に失敗しました'
      this.isUploading = false

    })
  }

  clickFileIcon() {
    document.getElementById("file")!.click()
  }

  clickImageFileIcon() {
    document.getElementById("imageFile")!.click()
  }

  async updateImageFile(newFile: any, oldFile:any) {
    this.isUploading = true
    let file = newFile.file
    var storageRef = firebase.storage().ref()
    let filefolder = Common.createUUID()
    let fileName = `onayamis/${filefolder}/images/${file.name}`
    file = await Common.getCompressImageFileAsync(file)
    storageRef.child(fileName).put(file).then(() => {
      let url = Common.createURL(`onayamis/${filefolder}/images/${file.name}`)
      let func = () => {
        this.detail = Common.addTextCursor(document.getElementById('detail')!, `![](${url})`)
        this.isUploading = false
      }
      var img = new Image()
      img.src = url
      img.onload = func
      img.onerror = () => {
        setTimeout(() => {
          img.src = url
        }, 500)
      }
    })
  }

  jump(registId: string) {
    if (this.$route.params.id) {
      this.$router.push(`/onayami/${this.$route.params.id}`)
    } else {
      this.$router.push(`/onayami/${registId}`)
    }
  }

  isMobile() {
    return Common.isMobile()
  }
}
